@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
/* Fonts */

/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Poppins', sans-serif;
  font-family: var(--paragraph);
}

img {
  max-width: 100%;
  display: block;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Root */

:root {
  --brown: #402F27;
  --black: #1F1F1F;
  --no-white: #F5F5F4;
  --green: #385738;
  --title: 'DM Serif Display', serif;
  --paragraph: 'Poppins', sans-serif;
  --h1: 60px;
  --h2: 50px;
  --h3: 40px;
  --h4: 30px;
  --p: 20px
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'DM Serif Display', serif;
  font-family: var(--title);
  color: #402F27;
  color: var(--brown);
}

h1 {
  font-size: 60px;
  font-size: var(--h1);
}

h2 {
  font-size: 50px;
  font-size: var(--h2);
}

h3 {
  font-size: 40px;
  font-size: var(--h3);
}

h4 {
  font-size: 30px;
  font-size: var(--h4);
}

p,
a,
li,
button {
  color: #402F27;
  color: var(--brown);
}

/* Tamaños del contenido*/

.container {
  max-width: 1100px;
  margin: auto;
}

/* Botones */

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.btn {
  width: 12rem;
  height: auto;
  margin: 1em 0;
}

button.btn .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #402F27;
  background: var(--brown);
  border-radius: 1.625rem;
}

button.btn .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #F5F5F4;
  background: var(--no-white);
}

button.btn .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.btn .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #F5F5F4;
  border-top: 0.125rem solid var(--no-white);
  border-right: 0.125rem solid #F5F5F4;
  border-right: 0.125rem solid var(--no-white);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

button.btn .btn-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #402F27;
  color: var(--brown);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #F5F5F4;
  background: var(--no-white);
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}

button:hover .btn-text {
  color: #F5F5F4;
  color: var(--no-white);
}

/* Efecto títulos */

.title__after::after {
  content: '';
  width: 30px;
  height: 2px;
  background: #BFBFBF;
  display: inline-block;
  margin-left: 6px;
  animation-name: parpadeo;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

.title__before::before {
  content: '';
  width: 30px;
  height: 2px;
  background: #BFBFBF;
  display: inline-block;
  margin-right: 6px;
  animation-name: parpadeo;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}
img {
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.logo-home {
    box-shadow: none;
    margin: 10px auto;
}

/* Brillo provisional imagen flores 2 */
img.servicios__flores-img-2 {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.home__cabecera {
    text-align: center;
}

.servicios {
    padding: 20px;
    background-color: var(--no-white);
}

/* Ramos de flores */

.servicios__flores-desc {
    margin: 2em 0;
    display: flex;
    justify-content: space-between;
}

.ramos-list {
    flex: 0 0 50%;
}

.servicios__flores-img {
    flex: 0 0 50%;
    max-width: 130px;
}

.servicios__flores-img-2 {
    max-width: 70%;
    margin-top: -100px;
    margin-left: -20px;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ramos-list__item {
    margin: 15px 0;
}

.ramos-list__item a {
    margin-right: 5px;
    transition: margin-right 0.4s;
    text-decoration: none;
    color: var(--black);
}

.ramos-list__item a:hover {
    margin-right: 15px;
}

.ramos-list__item svg {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);

}


.ramos-list__item::after,
.ramos-list__item:first-child::before {
    content: '';
    display: block;
    height: 1px;
    width: 180px;
    background-color: #BFBFBF;
    margin: 10px 0;
}

/* Plantas */

.servicios__plantas {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 2em;
}

.servicios__plantas-img {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 150px;
    margin: auto;
}

.servicios__plantas-img-1 {
    align-self: flex-end;
    margin-left: 10px;
}

.servicios__plantas-img-2 {
    margin-right: 10px;
}

/* Funerarios */

.servicios__funerarios {
    display: flex;
    flex-direction: column;
}

.servicios__funerarios-center img {
    margin: auto;
}

.servicios__funerarios-right,
.servicios__funerarios-left {
    display: none;
}

/* Gallery */

.gallery {
    margin: 2em 20px;
}

.gallery h3 {
    text-align: center;
    margin-bottom: 1em;
}

/* Contacto */

.contact {
    padding: 20px;
    background-color: var(--no-white);
}

.contact h3 {
    text-align: center;
    margin-bottom: 1em;
}

.contact__card {
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin-bottom: 2em;
}

.map iframe {
    width: 100%;
    border-radius: 6px 6px 0 0;
}

.contact__details {
    padding: 1.5em 1em;
}

.contact__details li {
    margin: 10px 0;
    display: flex;
    width: 100%;
    align-items: center;
}

.contact__details li svg {
    font-size: 22px;
    margin-right: 10px;
}

.sticky__whatsapp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
}

.sticky__whatsapp-link {
    padding: 6px 10px;
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.kit-digital{
    margin: auto;
    text-align: center;
    padding: 1em 0;
    background-color: var(--no-white);
}

.kit-digital__img{
    max-width: 800px;
    margin: auto;
    margin-bottom: .5em;
}

/************** TABLET ************/

@media screen and (min-width: 768px) {

    .ramos-list {
        align-self: center;
    }

    .servicios__flores-img {
        max-width: 200px;
        margin: auto;
    }

    .servicios__plantas-desc p,
    .servicios__funerarios p {
        max-width: 500px;
    }

    .servicios__plantas-img {
        max-width: 250px;
    }

    .cards-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2em;
    }

    .contact__card {
        margin: auto 15px;
    }
}

/*************** ESCRITORIO **************/

@media screen and (min-width: 992px) {

    /* Ramos */

    .servicios__flores {
        margin-top: 6em;
    }

    .servicios__flores-desc {
        justify-content: flex-start;
        width: 100%;
    }

    .ramos-list {
        align-self: flex-start;
        flex: 0 0 40%;
    }

    .ramos-list__item::after,
    .ramos-list__item:first-child::before {
        width: 300px;
    }

    .servicios__flores-img {
        max-width: 230px;
        display: flex;
        margin: 0;
    }

    .servicios__flores-img-1,
    .servicios__flores-img-2 {
        max-width: none;
        flex-direction: 0 0 30%;
    }

    .servicios__flores-img-2 {
        margin: 0;
        margin-left: 1em;
        align-self: flex-start;
    }

    /* Plantas */

    .servicios__plantas {
        margin-top: 6em;
        flex-direction: row;
        justify-content: space-between;
    }

    .servicios__plantas-img {
        max-width: none;
        flex: 0 0 40%;
        margin: 0;
        flex-direction: row;
        justify-content: space-between;
    }


    .servicios__plantas-img-1,
    .servicios__plantas-img-2 {
        margin: 0;
    }

    .servicios__plantas-img-2 {
        align-self: flex-start;
        margin-left: 15px;
    }

    .servicios__plantas-desc {
        flex: 0 0 40%;
    }

    /* Funerarios */

    .servicios__funerarios {
        margin: 6em 0;
    }

    .servicios__funerarios-right,
    .servicios__funerarios-left {
        display: block;
    }

    .servicios__funerarios {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .servicios__funerarios-left,
    .servicios__funerarios-center {
        margin-right: 50px;
    }

    .servicios__funerarios-center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .servicios__funerarios-center img {
        margin: 0;
    }



    /* Galería */

    .gallery {
        margin: 4em 20px;
    }

    /* Contacto */

    .cards-container {
        justify-content: center;
    }

    .contact__card {
        margin: auto 30px;
    }
}
